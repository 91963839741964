const dev = {
	API_ENDPOINT_URL: "http://localhost:8080/grupo-grande-oeste/api",
	PUBLIC_PREFIX_PATH: "",
};

const prod = {
	API_ENDPOINT_URL:
		"https://grupo-grande-oeste-back-na9g4.ondigitalocean.app/grupo-grande-oeste-back2/grupo-grande-oeste/api",
	PUBLIC_PREFIX_PATH: "",
};

const test = {
	API_ENDPOINT_URL: "https://homolog.dimo.com.br/grupo-grande-oeste-back/api",
	PUBLIC_PREFIX_PATH: "/grupo-grande-oeste",
};

const getEnv = () => {
	switch (process.env.REACT_APP_ENV) {
		case "development":
			return dev;
		case "production":
			return prod;
		case "test":
			return test;
		default:
			return dev;
	}
};

export const env = getEnv();
